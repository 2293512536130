import React from "react";
import Buy from "../../Components/Buy";

const HomePage = () => {
  return (
    <div>
      <Buy />
    </div>
  );
};

export default HomePage;
