import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import Banner from "../Banner/index";

const NavMenu = () => {
  const links = [
    {
      name: "telegram",
      path: "https://t.me/lilbubcommunitycto",
      img: "https://i.imgur.com/YKgLqdN.png",
    },
    {
      name: "dexscreener",
      path: "https://dexscreener.com/solana/CzNxkNCLTR3C7QSiB92eQUeJa8MgyWZq2c31CMs2ZiFF",
      img: "https://i.imgur.com/t9sHc2x.jpg",
    },
    {
      name: "twitter",
      path: "https://x.com/CtoBub",
      img: "https://i.imgur.com/CNLTWEV.png",
    },
  ];

  return (
    <div className="nav-container">
      <div className="info-1 center-center">
        <div>SUPPLY: 1 Billion Tokens</div>
        <div>Contract: Revoked</div>
        <div>Liquidty: Locked & Burned</div>
        <div>Buy/Sell Taxes: 0%</div>
      </div>
      <ul className="links-container center-center">
        {links.map((link) => (
          <li className="link social-links" key={link.name}>
            <Link to={link.path} className="link-styles center-center">
              <img className="social-icon" src={link.img} />
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
      <div className="main-container center-center">
        <img src="https://i.imgur.com/AV5ZbKV.png" alt="logo" />
        <div className="title-name">OFFICIAL LIL BUB CTO</div>
      </div>
      <Banner />
    </div>
  );
};

export default NavMenu;
