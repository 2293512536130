import React from "react";
import "./styles.css";

const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <div className="info">
          This Lil BUB memecoin project is a fan-based initiative created by
          enthusiasts and admirers of the beloved internet personality, Lil BUB.
          We are not affiliated, associated, authorized, endorsed by, or in any
          way officially connected with Lil BUB, her estate, or any other legal
          entity that holds rights to the name and likeness of Lil BUB. All
          names, trademarks, and images are used solely for identification and
          reference purposes. Any resemblance to actual persons, living or dead,
          or actual events is purely coincidental. The use of Lil BUB's name is
          for illustrative purposes only and does not imply any official
          endorsement or connection.
        </div>
      </div>
    </>
  );
};

export default Footer;
