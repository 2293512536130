import React from "react";
import "./styles.css";

const Hero = () => {
  return (
    <div className="hero-container">
      <img src="https://i.imgur.com/H3mqBxU.png" />
    </div>
  );
};

export default Hero;
