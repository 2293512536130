import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles.css";
import NavMenu from "./Components/NavMenu";
import Footer from "./Components/Footer";
import HomePage from "./Pages/Home";

export default function App() {
  return (
    <div className="App">
      <Router>
        <NavMenu />
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}
