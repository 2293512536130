import "./styles.css";
import React, { useState, useEffect } from "react";
import axios from "axios";

const Banner = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const [currentPrice, setCurrentPrice] = useState(null);
  const [hourChange, setHourChange] = useState(null);
  const [dayChange, setDayChange] = useState(null);

  const getBubData = async () => {
    try {
      const response = await axios(
        `https://api.coingecko.com/api/v3/coins/lil-bub?localization=false&tickers=false&market_data=true&community_data=true&developer_data=false&sparkline=false`
      );
      setData(response.data);
      setCurrentPrice(
        convertToInt(response.data.market_data.current_price.usd)
      );
      setHourChange(
        response.data.market_data.price_change_percentage_1h_in_currency.usd
      );
      setDayChange(
        response.data.market_data.price_change_percentage_24h_in_currency.usd
      );
    } catch {
      setError(true);
    }
  };

  const convertToInt = (num) => {
    let converted = parseFloat(num);
    return converted;
  };

  const copyToClipboard = (address) => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        alert("Address copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy address: ", err);
      });
  };

  useEffect(() => {
    getBubData();
  }, []);

  return (
    <div className="banner-container">
      {error && <div>Error fetching data</div>}
      {data && (
        <>
          <div
            className="address center-center"
            onClick={() => copyToClipboard(data.platforms.solana)}
          >
            Contract Address: {data.platforms.solana}{" "}
            <img className="copy" src="https://i.imgur.com/sF55vXb.png" />
          </div>
          <div className="info-2">
            <div>Current Price: ${currentPrice}</div>
            <div>
              1 Hour Change:{" "}
              <span className={hourChange >= 0 ? "green" : "red"}>
                {hourChange}%
              </span>
            </div>
            <div>
              24 Hour Change:{" "}
              <span className={dayChange >= 0 ? "green" : "red"}>
                {dayChange}%
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Banner;
